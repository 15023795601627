import { Avatar, Button, Card, Menu, Stack, Typography, TextField, Box, MenuItem } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh'
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRef } from "react";
import io from "socket.io-client";
import socket from "./sockets";
import { RHFTextField } from "../../../components/hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const CryptoJS = require("crypto-js");

function extractYouTubeVideoId(url) {
  // Regular expressions to match various YouTube URL formats
  const patterns = [
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtu.be\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_\-?]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?list=[a-zA-Z0-9_-]+&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube-nocookie\.com\/embed\/([a-zA-Z0-9_-]+)/,
  ];

  // Try each pattern and return the video ID if a match is found
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }

  // If no match is found, return null or handle the case as needed
  return null;
}

const YoutubeLive = () => {
  const { lectId, lectTitle, lectDesc, ytUrl } = useParams();
  let newLink = "";
  const decodedYtUrl = decodeURIComponent(ytUrl);

  Array.from(decodedYtUrl).forEach((elem) => {
    if (elem !== "@") newLink += elem;
    else newLink += "/";

    // console.log('newLin', newLink)
  });
  const showChatHandler = async () => {
    const resp = await axios.put(`${process.env.REACT_APP_LIVE_URL}/chat/hide/${chatId}`, { status: true })
    if (resp.status == 200) {
      toast.success(resp.data.message)
      // getHistory();
    }
  }
  const hindeChatHandler = async () => {
    const resp = await axios.put(`${process.env.REACT_APP_LIVE_URL}/chat/hide/${chatId}`, { status: false })
    if (resp.status == 200) {
      toast.success(resp.data.message)
      // getHistory();
    }
  }
  // console.log(newLink);
  const videoId = extractYouTubeVideoId(newLink);
  const newUrl = `https://www.youtube.com/embed/${videoId}`;
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const name = detailObject?.username;
  const username = detailObject?.username;
  // console.log(detailObject?.data);
  const id = detailObject?.adminId;
  const profileIcon =
    detailObject?.profilePhoto ??
    "https://storage-upschindi.s3.ap-south-1.amazonaws.com/data/images/avatar.png";
  // let socket = io.connect('http://localhost:3001', { transports: ['websocket'] })
  const [data, setData] = useState([])
  const [state,setState] = useState(false)
  const [msg, setMsg] = useState("");
  const [chatId, setChatId] = useState("")
  const [messagelist, setMessageList] = useState([]);
  const [nameOfUser, setNameOfUser] = useState();
  const [IconIfUser, setIconIfUser] = useState("");
  const [isLiveFullScreen, setIsLiveFullScreen] = useState(false);
  // const containerRef = useRef(null);
  const getHistory = async () => {

    const response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/chat/chat-history/${lectId}`);
    console.log("History ", response.data.data)
    setData(response.data.data)
    if(response.status){
      // toast.success(response.data.message)
    }
  }
  const joinRoom = () => {
    const roomId = lectId;

    socket.emit("create", roomId);
  };
  const qhandleHideMessage = () => {
    // Close the menu
    handleMenuClose();

    // Initiate the chat hiding process
    hindeChatHandler();
  };

  useEffect(() => {
    joinRoom();
  }, []);
  const sendMessage = async (msgg) => {
    // const msgg
    const roomId = lectId;
    // console.log('line26',details)
    await socket.emit("addUser", { username, id });
    // console.log(msgg  + " "+ name + " "+roomId + " "+ profileIcon);
    await socket.emit("send-message", detailObject?.adminId, msgg, name, roomId, profileIcon);
    setMsg("");
    socket.emit("getMessages", lectId);
    socket.on("roomMessages", (messages) => {
      // toast.success("Chat Fetched Successfully")
      setData(messages); // Set initial chat history
    });
  };

  const arrivalMessage = (message, name, userIconUrl) => {
    // setMessageList((prev) => [
    //   ...prev,
    //   { message: message, name: name, icon: userIconUrl },
    // ]);
  };

  useEffect(() => {
    socket.on("receive-message", (message, name, userIconUrl) => {
      // console.log('line32', message)
      arrivalMessage(message, name, userIconUrl);
      setNameOfUser(name);
      setIconIfUser(userIconUrl);
      // console.log(message)
    });
  }, []);
  // socket.emit("getMessages", lectId);
  //   socket.on("roomMessages", (messages) => {
  //     // toast.success("Chat Fetched Successfully")
  //     setData(messages); // Set initial chat history
  //   });

  useEffect(() => {
    // getHistory()
    socket.emit("getMessages", lectId);
    socket.on("roomMessages", (messages) => {
      // toast.success("Chat Fetched Successfully")
      setData(messages); // Set initial chat history
    });

  }, [state])

  const [users, usersSet] = useState();
  const [lectDetails, setlectureDetails] = useState();
  //Lecture Resource
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    // console.log(lectId);
    async function fetchDetails() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureResourceDetails?Lecture_id=${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // debugger;
      // console.log(responseJson.data);
      usersSet(responseJson.data);
      // setLoading(false)
      // setLoadingTable(false)
      //Lecture Details
      const fullResponseForLecture = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails/${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJsonForLecture = await fullResponseForLecture.json();
      setlectureDetails(responseJsonForLecture.data);
    }

    fetchDetails();
  }, []);

  const handleChange = (e) => {
    setMsg(e.target.value);
    // console.log(e.target.value)
    // if (e.target.value !== '') { setMsg(e.target.value) }
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleMenuOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setChatId(message?._id)
    setSelectedMessage(message);
  };

  /**
   * Closes the context menu for a chat message.
   * Called when the user clicks outside of the context menu or when the user closes the menu.
   * @function
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMessage(null);
  };

  /**
   * Handles the deletion of a chat message.
   * This function is responsible for executing the logic to delete a chat message.
   * The implementation details need to be added.
   * @function
   * @returns {Promise<void>}
   */
  const handleDelete = async () => {
    // Add delete logic here
    handleMenuClose();

    const token = localStorage.getItem("token");

    const url = `${process.env.REACT_APP_LIVE_URL}/chat/delete/${chatId}`;

    /**
     * The HTTP request method
     * @type {string}
     */
    const method = "PUT";

    /**
     * The request headers
     * @type {import("axios").AxiosRequestHeaders}
     */
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    /**
     * The request body
     * @type {any}
     */
    const data = {};

    /**
     * The response data
     * @type {any}
     */
    const response = await axios.delete(url, { headers });

    /**
     * Handle the response data
     */
    if (response.status == 200) {
      // TODO: Add logic to handle the response data
      toast.success("Message Deleted successfully")
      socket.emit("getMessages", lectId);
      socket.on("roomMessages", (messages) => {
        // toast.success("Chat Fetched Successfully")
        setData(messages); // Set initial chat history
      });
    } else {
      // TODO: Add logic to handle errors
    }
  };
  const deleteMessage = (messageId) => {
    socket.emit("deleteMessage", chatId, lectId);
    toast.success("Message Deleted successfully")

    socket.emit("getMessages", lectId);
    // socket.on("roomMessages", (messages) => {
    //   // toast.success("Chat Fetched Successfully")
    //   setData(messages); // Set initial chat history
    // });
  };

  // Emit hideMessage event
  const hideMessage = (messageId) => {
    socket.emit("hideMessage", chatId, false,lectId);
    toast.success("Message Hided successfully")

    socket.emit("getMessages", lectId);
    // socket.on("roomMessages", (messages) => {
    //   // toast.success("Chat Fetched Successfully")
    //   setData(messages); // Set initial chat history
    // });
  };
  const showMessage = (messageId) => {
    socket.emit("hideMessage", chatId, true,lectId);
    toast.success("Message Visible successfully")

    // socket.emit("getMessages", lectId);
    // socket.on("roomMessages", (messages) => {
    //   // toast.success("Chat Fetched Successfully")
    //   setData(messages); // Set initial chat history
    // });
  };
  return (
    <div
      className="parent"
      style={{
        height: "100%",
      }}
    >
      <ToastContainer position="bottom-center" />
      <Stack direction={isLiveFullScreen ? "column" : "row"} gap="20px">
        <Stack
          direction="column"
          sx={{ width: "100%", height: "80vh !important" }}
        >
          <div
            style={{
              height: isLiveFullScreen ? "70%" : "60%",
              width: "100%",
              padding: "20px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "black",
                borderRadius: "10px",
                marginBottom: "10px",
                overflowY: "auto",
              }}
            >
              <iframe
                width={isLiveFullScreen ? "100%" : "780"}
                height={isLiveFullScreen ? "340" : "255"}
                // src='https://www.youtube.com/embed/DAEnJzUNZYE?si=59XwYssuy9g29qEN'>
                src={newUrl}
                allowFullScreen
              >
                {/* // src={newUrl}> */}
              </iframe>
            </div>
          </div>
          <h5 style={{ height: "5%" }}>{lectDetails?.lecture_title}</h5>
          <div style={{ height: "50%", width: "100%" }}>
            <Card
              sx={{
                height: "100%",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4 style={{}}>{lectDesc}</h4>
              {/* {console.log(users)} */}
              {users?.length !== 0
                ? users?.map((item, index) => {
                  return (
                    <div key={index}>
                      {item?.resourceType == "pdf" ? (
                        <Stack direction="row" alignItems="center">
                          {item?.title}:
                          <a href={item?.upload_file?.fileLoc}>
                            <PictureAsPdfIcon />
                          </a>
                        </Stack>
                      ) : (
                        <p>
                          {item?.title}: {item?.upload_file?.fileLoc}
                        </p>
                      )}
                    </div>
                  );
                })
                : "Nothing"}
            </Card>
          </div>
        </Stack>
        <Stack
          direction="column"
          sx={{ width: isLiveFullScreen ? "100%" : "70%" }}
        >
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
            }}
          >
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px ",
                paddingRight: "10px",
                alignItems: "center",
                background: "rgba(255, 137, 158, 0.12)",
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* fill="#9603F2" */}
                <path
                  d="M7.5 24C7.075 24 6.71875 23.8563 6.43125 23.5688C6.14375 23.2812 6 22.925 6 22.5V19.5H25.5V6H28.5C28.925 6 29.2812 6.14375 29.5687 6.43125C29.8562 6.71875 30 7.075 30 7.5V30L24 24H7.5ZM0 22.5V1.5C0 1.075 0.14375 0.71875 0.43125 0.43125C0.71875 0.14375 1.075 0 1.5 0H21C21.425 0 21.7812 0.14375 22.0688 0.43125C22.3563 0.71875 22.5 1.075 22.5 1.5V15C22.5 15.425 22.3563 15.7813 22.0688 16.0688C21.7812 16.3563 21.425 16.5 21 16.5H6L0 22.5ZM19.5 13.5V3H3V13.5H19.5Z"
                  fill="#EA506B"
                  fill-opacity="0.75"
                />
              </svg>
              &nbsp; Live Comments
              <Button>
                <RefreshIcon onClick={()=>setState(!state)} />
              </Button>
              <Button onClick={() => setIsLiveFullScreen(!isLiveFullScreen)}>
                {!isLiveFullScreen ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
              </Button>
              
            </div>

            <div
              style={{
                overflowY: "auto", // Enable scrolling if content overflows
                padding: "20px",
                marginBottom: "70px",
                display: "flex",
                flexDirection: "column-reverse", // Start from the bottom for new messages
                height: isLiveFullScreen ? "50vh" : "60vh",
                scrollBehavior: "smooth", // Optional: makes scrolling smooth
              }}
            >
              {data?.map((msg, index) => {

                return (
                  <Card
                    key={index}
                    sx={{
                      margin: '15px 0', // Increased margin for better spacing
                      padding: '35px 15px',   // Increased padding for each card
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderRadius: '8px', // Optional: adds rounded corners
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: shadow for each card
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary">
                        <b>{msg?.userId?.FullName||msg?.adminId?.FullName}</b> {/* User name */}
                      </Typography>
                      <Typography variant="body2">{msg?.message}</Typography> {/* Message */}
                      <Typography variant="caption" color="text.secondary">
                        {msg?.created_at?.slice(0, 10) + " " + msg?.created_at?.slice(11, 19)} {/* Message time */}
                      </Typography>
                    </div>
                    {/* More Options Button */}
                    <IconButton onClick={(e) => handleMenuOpen(e, msg)}>
                      <MoreVertIcon />
                    </IconButton>
                    {/* Menu for More Options */}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl && selectedMessage === msg)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={()=>deleteMessage(msg?._id)}>Delete</MenuItem>
                      {/* <MenuItem onClick={()=>handleDelete(msg?._id)}>Delete</MenuItem> */}
                      <MenuItem onClick={() => {
                        if (msg?.status) {
                        // hindeChatHandler()
                        hideMessage(msg?._id)
                        } else {
                          // showChatHandler();
                        showMessage(msg?._id)

                        }
                      }}>
                        {msg?.status ? "Hide" : "Visible"}
                      </MenuItem>
                    </Menu>
                  </Card>
                );
              })}
            </div>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                position: "absolute",
                width: "100%",
                bottom: "0",
                padding: "10px",
              }}
            >
              {" "}
              <TextField
                type="text"
                value={msg}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <Button
                // ref={inputRef}
                variant="outlined"
                disabled={msg.trim() === ""}
                onClick={() => sendMessage(msg)}
              // placeholder="Enter your message"
              >
                Send
              </Button>
            
            </Stack>

            {/* <h4>AAyein</h4> */}
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};

export default YoutubeLive;
