import * as Yup from "yup";
import { useState, useEffect } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// @mui
import { Stack, Typography, Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
export default function AddCoupon() {
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(dayjs());
  const [msg, setMsg] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [global, setGlobal] = useState(false);
  const [val, setVal] = useState(false);
  const [file, setFile] = useState()
  const changeHandler = async (event) => {
    setFile(event.target.files[0])


  };
  const RegisterSchema = Yup.object().shape({
    // title: Yup.string().required("couponCode required"),
    // desc: Yup.string().required("couponCode required"),
    // type: Yup.string().required("couponCode required"),

  });
  const defaultValues = {
    title: "",
    desc: "",
    type: "",
    is_active: "true"
  };
  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  // getting user data
  const [batches, setBatches] = useState([]);
  const [tests, setTests] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [testseriesId, setTestseriesId] = useState("");
  const [studentsIds, setStudentsIds] = useState([]);
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    async function fetchStudents() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      const responseJson = await fullResponse.json();
      //console.log(responseJson.data);
      const extractids = responseJson.data.map((item) => item.id);
      // const extractids = ['63d18abf0e7f5bc9451bff0c','63d4fbdb166ac3c884807b78'];
      setStudentsIds(extractids);
      //console.log(extractids);

    }

    async function fetchBatches() {
      const batchResponce = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      //console.log(batchResponce);
      const batchesJson = await batchResponce.json();
      const batchesData = batchesJson.data;
      const filteredBatches = batchesData.filter((batch) => batch?.student?.length > 0);
      // console.log(filteredBatches);
      // setBatches(batchesJson.data);
      setBatches(filteredBatches);
    }

    async function fetchTests() {
      const testResponce = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getallTestSeriesdetails`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      //console.log(testResponce);
      const testsJson = await testResponce.json();
      const testsData = testsJson.data;
      const filteredTests = testsData.filter((test) => test?.student?.length > 0);
      // console.log(filteredTests);
      // setTests(testsJson.data);
      setTests(filteredTests);

    }

    // fetchStudents();
    fetchBatches();
    fetchTests();
    setLoading(false);

  }, []);
  const handleNotificationChange = (event) => {
    setIsNotification(event.target.checked);
  };

  //console.log("batches",batches);
  //console.log("tests",tests);
  //console.log("studentsIds",studentsIds);

  console.table({ batchId, testseriesId })
  const [userDataa, setUserDataa] = useState([]);
  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    // console.log(data);
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    async function fun1(data) {
      let updatedUserDataa = [];
      // debugger;
      if (data.user_type === "all") {
        //console.log("all");
        // updatedUserDataa = studentsIds;
        setGlobal(true);
        setTestseriesId("")
        setBatchId("");
        //console.log('users data', updatedUserDataa);
      } else if (data.user_type === "test") {
        //console.log("batch");
        tests.forEach((test) => {
          if (test._id === data.user_type1) {
            //console.log('condition matches');
            //console.log(test.students);
            updatedUserDataa = test.student;
          }
        });
      } else {
        //console.log("test");
        batches.forEach((test) => {
          if (test._id === data.user_type1) {
            //console.log('condition matches');
            //console.log(test.student);
            updatedUserDataa = test.student;
          }
        });
      }
      return updatedUserDataa;
    }

    try {
      const updatedUserDataa = await fun1(data);
      // console.log(updatedUserDataa);
      const reqObject = {
        // userIdArr: updatedUserDataa,
        isNotification: isNotification,
        testseriesId: testseriesId,
        batchId: batchId,
        global,
        title: data.title,
        desc: data.desc,
        file: file,
        isActive: data.is_active,
        type: data.type
      };
      //console.log(reqObject);
      const formData = new FormData();
      for (const key in reqObject) {
        if (Array.isArray(reqObject[key])) {
          // If the value is an array, append each element individually
        // formData.append(`${key}[]`, reqObject[key])
        } else {
          // Otherwise, append the value directly
          formData.append(key, reqObject[key]);
        }
      }
      formData.append("userIdArr",[])
      setLoading(true);
      //console.log("Api call start");
      const response = await axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addAlert`, formData, config);
      //console.log(response);
      toast.success(response.data.msg);
      setVal(false);
      setMsg("Alert Added");
      setLoading(false);
      reset();
      setTimeout(() => {

        window.location.href = "/dashboard/customNotification";
      }, 1000)
    } catch (error) {
      toast.error(error.message);
      //console.log(error);
      setLoading(false);
    }
  };


  const [user1, setUser1] = useState('');
  //console.log(starting.$d);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Alert </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="title" label="Title" />
              <RHFTextField
                name="image"
                label="Upload Image"
                inputProps={{
                  type: "file",
                  accept: "image/*" // Accept only image files
                }}
                onChange={changeHandler}
              />

            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="desc" label="Description" />
              {/* <RHFTextEditor name="desc" label="Description" /> */}
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="user_type" label="User Type" select>
                <MenuItem value="all" onClick={() => {
                  setUser1("all");
                  setBatchId("")
                  setTestseriesId("")
                  setGlobal(true)
                  }}>All</MenuItem>
                <MenuItem value="batch" onClick={() => setUser1("batch")}>Batches</MenuItem>
                <MenuItem value="test" onClick={() => setUser1("test")}>Test Series</MenuItem>
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              {user1 === "batch" ? (
                <RHFTextField onChange={(e) => {
                  setTestseriesId("");

                  setBatchId(e.target.value);
                }} name="user_type1" label="Batches" select>
                  {batches?.map((batch) => (
                    <MenuItem key={batch._id} value={batch._id}>
                      {batch.batch_name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              ) : user1 === "test" ? (
                <RHFTextField onChange={(e) => {
                  setBatchId("");
                  setTestseriesId(e.target.value);
                }} name="user_type1" label="Test Series" select>
                  {tests?.map((test) => (
                    <MenuItem key={test._id} value={test._id}>
                      {test.testseries_name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              ) : null}
            </Stack>

            {/* Add checkbox for "Is Notification" */}
            {/* <Stack direction="row" alignItems="center" spacing={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...methods.register("isNotification")}
                    color="primary"
                    checked={isNotification}
                    onChange={handleNotificationChange}
                  />
                }
                label="Send as a  Notification "
              />
            </Stack> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#EA506B",
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>

        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}